import request from '../request';
/**
 * @name 根据父级区域ID获取子级区域信息
 * @param { String } parentId
 */
export function getRegionListByParentId(parentId) {
  return request(`/configuration/regionInfo/getRegionListByParentId`, {
    method: 'GET',
    body: {
      parentId,
    },
    auth: false,
  });
}

/**
 * @name 获取搜索框获取地址
 * @param { String } site
 */
export function getSearchSite(site) {
  return request(`/configuration/regionInfo/searchSite`, {
    method: 'GET',
    body: {
      site,
    },
  });
}
