var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{class:_vm.$style.wrapContent},[_c('div',{class:_vm.$style.label},[_c('el-select',{class:_vm.$style.searchInput,attrs:{"filterable":"","remote":"","placeholder":_vm.textObj[_vm.tabType],"remote-method":_vm.handleSearch,"popper-class":"iot-select-project"},on:{"change":_vm.handleSearchByChange},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}},_vm._l((_vm.optionsList),function(item,itemIdx){return _c('el-option',{key:'item' + itemIdx,class:{
          scrollTextShort:
            item.projectName.length > 17 && item.projectName.length < 29,
          scrollText:
            item.projectName.length > 28 && item.projectName.length < 37,
          scrollTextNormal:
            item.projectName.length > 36 && item.projectName.length < 55,
          scrollTextLong: item.projectName.length > 54,
        },attrs:{"label":item.projectName,"value":item.id || item.projectId}})}),1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }