<template>
  <div v-if="visible" :class="visible ? 'screen-header__search' : 'disabled'">
    <div class="search-main">
      <div class="search-content">
        <el-select
          v-model="searchVal"
          @change="handleSearchByChange"
          filterable
          remote
          :placeholder="$t('screen.ph.tip4')"
          :remote-method="handleSearch"
          class="search-input"
          popper-class="iot-select-dropdown"
        >
          <el-option-group
            v-for="(group, idx) in options"
            :key="'group' + idx"
            :label="group.label"
          >
            <el-option
              v-for="(item, itemIdx) in group.options"
              :key="'item' + itemIdx"
              :label="item.areaName"
              :value="`${item.areaName}##${group.label}`"
            ></el-option>
          </el-option-group>
        </el-select>
      </div>
      <div v-if="!isDefault">
        {{ $t('screen.ph.hotCity') }}
      </div>
      <ul class="area-lists">
        <li
          v-for="(item, index) in hotCities"
          :key="index"
          @click="handleSelectHotCity(item, index)"
        >
          <span v-if="!isDefault">{{ item }}</span>
          <span v-else>{{ item.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import { getSearchSite } from '@/services/configuration/region-info.js';
import { getAddressInfoByAmap } from '@/services/iot-electric-box/map.js';

const LEVEL_CITY = '市级';
const LEVEL_PROVINCE = '省级';

@Component()
export default class SearchAraea extends Vue {
  @Prop({ default: false, type: Boolean }) visible;

  @Emit('close')
  @Emit('update:visible')
  handleClose() {
    this.searchVal = '';
    return false;
  }
  @Watch('visible')
  onSearchChange(val) {
    if (!val) {
      this.searchVal = '';
    }
  }
  hotCities = [];
  defaultCities = [
    { title: '全国', value: '', level: 'CHINA', adcode: '100000' },
    { title: '北京', value: '北京', level: 'PROVINCE', adcode: '110000' },
    { title: '上海', value: '上海', level: 'PROVINCE', adcode: '310000' },
    { title: '广州', value: '广州', level: 'CITY', adcode: '440100' },
    { title: '深圳', value: '深圳', level: 'CITY', adcode: '440300' },
    { title: '成都', value: '成都', level: 'CITY', adcode: '510100' },
    { title: '天津', value: '天津', level: 'PROVINCE', adcode: '120000' },
    { title: '南京', value: '南京', level: 'CITY', adcode: '320100' },
    { title: '杭州', value: '杭州', level: 'CITY', adcode: '330100' },
    { title: '武汉', value: '武汉', level: 'CITY', adcode: '420100' },
    { title: '长沙', value: '长沙', level: 'CITY', adcode: '430100' },
    { title: '重庆', value: '重庆', level: 'PROVINCE', adcode: '500000' },
  ];
  isDefault = true;
  created() {
    const cities = this.$store.state.project.hotCities;
    if (Object.keys(cities).length < 3) {
      this.hotCities = this.defaultCities;
    } else {
      this.isDefault = false;
      this.hotCities = [...Object.keys(cities).slice(0, 12)];
    }
  }

  @Debounce(300)
  handleSearch = query => {
    this.handleSendSearchResult(query);
  };
  options = [
    {
      label: LEVEL_PROVINCE,
      options: [],
    },
    {
      label: LEVEL_CITY,
      options: [],
    },
  ];
  async handleSearchByChange(value) {
    const [name, level] = value.split('##');
    const result = await getAddressInfoByAmap({
      keywords: name,
    });
    if (!result) return false;
    const { districts } = JSON.parse(result);
    const adcode = districts[0].adcode;
    let option = {};
    if (level === LEVEL_PROVINCE) {
      option = {
        level: 'PROVINCE',
        adcode,
        value: name,
        title: name,
      };
    }
    if (level === LEVEL_CITY) {
      option = {
        level: 'CITY',
        adcode,
        value: name,
        title: name,
      };
    }
    this.handleChangeSite(option);
  }
  handleSendSearchResult(query) {
    if (!query) {
      this.options[0].options = [];
      this.options[1].options = [];
      return;
    }
    let reg = /^([\u4E00-\u9FA5])*$/;
    if (reg.test(query)) {
      this.getSearchRsult(query);
    } else {
      this.$message.warning('请输入正确地名');
    }
  }
  async getSearchRsult(site) {
    let searchAreadata = await getSearchSite(site);
    if (searchAreadata.provinces.length > 0 || searchAreadata.citys.length) {
      this.options[0].options = searchAreadata.provinces;
      this.options[1].options = searchAreadata.citys;
    } else {
      this.options[0].options = [];
      this.options[1].options = [];
      this.$message.warning('暂无数据');
    }
  }
  searchVal = '';
  async handleSelectHotCity(item) {
    let adcode = '';
    let itemValue = '';
    if (this.isDefault) {
      adcode = item.adcode;
      itemValue = item.title;
    } else {
      const result = await getAddressInfoByAmap({
        keywords: item,
      });
      const { districts } = JSON.parse(result);
      adcode = districts[0].adcode;
      itemValue = item;
    }
    let option = {
      value: itemValue,
      level: 'CITY',
      adcode,
    };
    this.handleChangeSite(option);
  }
  get currentMatchSite() {
    return this.$store.state.screen.currentMatchSite;
  }

  @Emit('changeSite')
  handleChangeSite(option) {
    return option;
  }
}
</script>
<style lang="less" scoped>
.screen-header__search {
  ::v-deep {
    .el-input__inner {
      background-color: #02051b;
      color: rgba(5, 200, 244, 1);

      &::-webkit-input-placeholder {
        color: rgba(5, 200, 244, 0.5);
      }
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: rgba(5, 211, 255, 0.2);
    }
    .el-input__inner {
      border-color: rgba(5, 211, 255, 0.2);
      border-radius: 0;
      height: 0.28rem;
      line-height: 0.28rem;
      padding: 0 0.15rem;
      font-size: 0.14rem;
    }
  }
  &.disabled {
    display: none;
  }
  font-size: 0.12rem;
  position: absolute;
  z-index: 20;
  width: 3.04rem;
  color: #18b0d1 !important;
  padding-top: 0 !important;
  .search-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
    padding: 0.05rem 0.14rem;
    line-height: 0.3rem;
    background: linear-gradient(
        90deg,
        rgba(5, 211, 255, 0.2) 0%,
        transparent 98%
      )
      no-repeat;
  }
  .search-main {
    box-sizing: border-box;
    .search-content {
      display: flex;
      margin: 0.2rem 0;
      .search-input {
        flex: 1;
        width: 1.65rem;
        color: #18b0d1 !important;
        border: #18b0d1;
      }
      .search-btn {
        flex: 0 0 0.6rem;
        display: inline-block;
        font-size: 0.14rem;
        border: 0.01rem solid rgba(5, 211, 255, 0.2);
        color: #18b0d1;
        border-radius: 0;
        height: 0.28rem;
        line-height: 0.25rem;
        text-align: center;
        margin-left: 0.06rem;
        cursor: pointer;
      }
    }
    .area-lists {
      margin: 0.1rem 0 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        flex-direction: column;
        margin: 0 0.15rem 0.15rem 0;
        cursor: pointer;
        font-size: 0.12rem;
      }
      .actived {
        color: #fff;
      }
    }
  }
}
</style>
<style lang="less">
.iot-select-dropdown {
  .el-scrollbar {
    background: none !important;
  }
  .el-scrollbar .el-select-dropdown__item {
    background: none !important;
  }
  .el-icon-arrow-up {
    line-height: 0.1rem;
    color: #18b0d1;
  }
  .el-icon-arrow-up:before {
    color: #18b0d1;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  &.el-select-dropdown {
    font-size: 0.12rem;
    background-color: rgba(2, 5, 27, 1);
    border: 0.01rem solid rgba(5, 211, 255, 0.2);
    .el-select-group__title {
      color: rgba(5, 211, 255, 1);
      border-bottom: 0.01rem solid rgba(5, 200, 244, 0.1);
      padding-left: 0.1rem;
    }
    .el-select-dropdown__item {
      color: #fff !important;
      font-size: 0.14rem;
      padding-left: 0.3rem;
      &:hover {
        color: #18b0d1 !important;
      }
    }
    .el-select-group__wrap:not(:last-of-type)::after {
      background: rgba(5, 211, 255, 0.2);
      display: none;
    }
  }
  &.el-popper[x-placement^='bottom'] .popper__arrow {
    border-bottom-color: rgba(5, 211, 255, 0.2);
    &::after {
      border-bottom-color: #11254e;
    }
  }
}
</style>
