<template>
  <div :class="$style.dialog" v-if="visible">
    <div :class="$style.main">
      <div :class="$style.header">
        <ul :class="$style.tabBox">
          <li
            v-for="(item, idx) in tabList"
            :key="item.name"
            :class="[$style.item, currentIndex === idx ? $style.actived : '']"
            @click.stop.prevent="handleItemClick(item, idx)"
          >
            {{ item.name }}
          </li>
        </ul>
        <i
          :class="$style.icon"
          class="TC tc-icon-guanbi1"
          @click.stop.prevent="handleClose"
        />
      </div>
      <component
        :class="$style.searchMain"
        :is="currentComponent"
        :visible="visible"
        :tab-type="tabType"
        :historyBackLink="historyBackLink"
        @changeSite="handleChangeSite"
      />
    </div>
    <div :class="$style.mask" @click.stop.prevent="handleClose"></div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SearchArea from './search-area.vue';
import SearchProject from './search-project.vue';

const COMPONENT_SEARCH_AREA = 'search-area';
const COMPONENT_SEARCH_PROJECT = 'search-project';
const COMPONENT_SEARCH_ATTENTION = 'search-attention';
const COMPONENT_SEARCH_RECOMMEND = 'search-recommend';

@Component({
  components: {
    SearchArea,
    SearchProject,
  },
})
export default class Search extends Vue {
  @Prop({ default: false, type: Boolean }) visible;
  @Prop({ default: false, type: Boolean }) singleTab;
  @Prop({ default: '/iot/screen', type: String }) historyBackLink;

  @Emit('close')
  @Emit('update:visible')
  handleClose() {
    return false;
  }

  @Watch('singleTab', { immediate: true })
  singleTabChange(value) {
    if (value) {
      this.tabList = [
        {
          name: this.$t('generalView.count.project'),
          type: COMPONENT_SEARCH_PROJECT,
        },
      ];
      this.currentComponent = COMPONENT_SEARCH_PROJECT;
    }
  }

  tabList = [
    {
      name: this.$t('generalView.count.project'),
      type: COMPONENT_SEARCH_PROJECT,
    },
    {
      name: this.$t('msg.area'),
      type: COMPONENT_SEARCH_AREA,
    },
    {
      name: this.$t('screen.like'),
      type: COMPONENT_SEARCH_ATTENTION,
    },
    {
      name: this.$t('screen.collection'),
      type: COMPONENT_SEARCH_RECOMMEND,
    },
  ];
  currentComponent = COMPONENT_SEARCH_PROJECT;
  tabType = COMPONENT_SEARCH_PROJECT;
  currentIndex = 0;
  handleItemClick(item, i) {
    this.currentIndex = i;
    const isProject =
      [
        COMPONENT_SEARCH_PROJECT,
        COMPONENT_SEARCH_ATTENTION,
        COMPONENT_SEARCH_RECOMMEND,
      ].indexOf(item.type) > -1;
    if (isProject) {
      this.currentComponent = 'SearchProject';
    } else {
      this.currentComponent = item.type;
    }
    this.tabType = item.type;
  }

  @Emit('changeSite')
  handleChangeSite(option) {
    return option;
  }
}
</script>
<style lang="less" module>
.dialog {
  position: fixed;
  z-index: 18;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  .mask {
    position: absolute;
    z-index: 19;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .main {
    position: absolute;
    z-index: 20;
    right: 0.95rem;
    top: 0.95rem;
    width: 3.04rem;
    height: 2.21rem;
    background: #02051b;
    box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    .header {
      height: 0.4rem;
      color: #18b0d1 !important;
      border: 0.01rem solid #0d2e45;
      background: rgba(2, 5, 27, 1);
      box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
      position: relative;

      .tabBox {
        height: 100%;
        line-height: 0.4rem;
        padding: 0 0.2rem;
        box-sizing: border-box;
        .item {
          font-size: 0.16rem;
          color: rgba(5, 200, 244, 0.5);
          display: inline-block;
          cursor: pointer;

          &.actived {
            color: rgba(5, 200, 244, 1);
          }
        }
        .item + .item {
          margin-left: 0.21rem;
        }
      }

      .icon {
        position: absolute;
        top: 50%;
        right: 0.2rem;
        transform: translateY(-50%);
      }
    }
    .searchMain {
      height: 1.81rem;
      padding: 0.2rem;
      box-sizing: border-box;
    }
  }
}
</style>
