<template>
  <div :class="$style.wrapContent" v-if="visible">
    <div :class="$style.label">
      <el-select
        v-model="projectName"
        @change="handleSearchByChange"
        filterable
        remote
        :placeholder="textObj[tabType]"
        :remote-method="handleSearch"
        :class="$style.searchInput"
        popper-class="iot-select-project"
      >
        <el-option
          v-for="(item, itemIdx) in optionsList"
          :key="'item' + itemIdx"
          :label="item.projectName"
          :value="item.id || item.projectId"
          :class="{
            scrollTextShort:
              item.projectName.length > 17 && item.projectName.length < 29,
            scrollText:
              item.projectName.length > 28 && item.projectName.length < 37,
            scrollTextNormal:
              item.projectName.length > 36 && item.projectName.length < 55,
            scrollTextLong: item.projectName.length > 54,
          }"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import { Ellipsis } from '@triascloud/x-components';
@Component({
  components: {
    Ellipsis,
  },
})
export default class Search extends Vue {
  @Prop({ default: false, type: Boolean }) visible;
  @Prop({ default: '', type: String }) tabType;
  @Prop({ default: '/iot/screen', type: String }) historyBackLink;

  projectName = '';
  list = [];
  optionsList = [];
  get textObj() {
    return {
      'search-project': this.$t('screen.ph.tip1'),
      'search-attention': this.$t('screen.ph.tip2'),
      'search-recommend': this.$t('screen.ph.tip3'),
    };
  }
  // created() {
  //   this.list =
  //     this.tabType === 'search-project'
  //       ? this.$store.state.project.projects
  //       : this.$store.state.project.attentionProjects;
  // }
  @Watch('tabType', { immediate: true })
  tabTypeChange(val) {
    if (val === 'search-project') {
      this.list = this.$store.state.project.projects;
    } else if (val === 'search-attention') {
      this.list = this.$store.state.project.attentionProjects;
    } else {
      this.list = this.$store.state.project.recommendProjects;
    }
    this.optionsList = this.list;
  }
  get storeProjects() {
    return this.$store.state.project;
  }
  @Watch('storeProjects', { immediate: true, deep: true })
  changeStoreProjects(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.list =
        this.tabType === 'search-project'
          ? newVal.projects
          : newVal.attentionProjects;
      this.optionsList = this.list;
    }
  }
  handleSearchByChange(itemId) {
    this.handleChangeSite({});
    this.$store.dispatch(
      'screen/setBackRouterLinkAction',
      this.historyBackLink,
    );
    const path = this.$route.path.startsWith('/iot/screen')
      ? 'projectBuilding'
      : 'buildingFloor';
    this.$router.push({
      path: `/iot/screen/${path}/${itemId}`,
    });
  }
  @Emit('changeSite')
  handleChangeSite(option) {
    return option;
  }

  @Debounce(300)
  handleSearch = query => {
    this.fetchSearch(query);
  };

  fetchSearch(query) {
    const searchList = this.list;
    if (query !== '') {
      this.optionsList = searchList.filter(
        v => v.projectName.indexOf(query) > -1,
      );
    } else {
      this.optionsList = searchList;
    }
  }

  @Emit('close')
  @Emit('update:visible')
  handleClose() {
    this.projectName = '';
    return false;
  }
  @Watch('visible')
  onSearchChange(val) {
    if (!val) {
      this.projectName = '';
    }
  }
}
</script>
<style lang="less" module>
.wrapContent {
  padding-top: 0 !important;
  width: 3.04rem;
  height: 1.81rem;
  .label {
    display: flex;
    margin: 0.2rem 0;
    .searchInput {
      flex: 1;
      width: 1.65rem;
    }
    :global(.el-input__inner) {
      background-color: #02051b;
      border-color: rgba(5, 211, 255, 0.2);
      border-radius: 0;
      height: 0.28rem;
      line-height: 0.28rem;
      padding: 0 0.15rem;
      font-size: 0.14rem;
      color: rgba(5, 200, 244, 1);

      &::-webkit-input-placeholder {
        color: rgba(5, 200, 244, 0.5);
      }
    }
  }
}
:global {
  .layerPopover {
    // z-index: 2021;
    margin-right: 15px;
    &.el-tooltip__popper.is-dark {
      background: #031d3f;
      border: 1px solid #05d3ff;
    }
    .popper__arrow,
    .popper__arrow::after {
      display: none;
    }
  }
}
</style>
<style lang="less">
.iot-select-project {
  .el-scrollbar {
    background: none !important;
    .el-select-dropdown__list li.scrollTextShort:hover span {
      animation: textMoveShort 3s infinite;
      display: block;
      width: fit-content;
    }
    .el-select-dropdown__list li.scrollText:hover span {
      animation: textMove 5s infinite;
      display: block;
      width: fit-content;
    }
    .el-select-dropdown__list li.scrollTextNormal:hover span {
      animation: textMoveNormal 7s infinite;
      display: block;
      width: fit-content;
    }
    .el-select-dropdown__list li.scrollTextLong:hover span {
      animation: textMoveLong 9s infinite;
      display: block;
      width: fit-content;
    }

    @keyframes textMoveShort {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-50%);
      }
    }
    @keyframes textMove {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-66%);
      }
    }
    @keyframes textMoveNormal {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-77%);
      }
    }
    @keyframes textMoveLong {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-88%);
      }
    }
  }
  .el-scrollbar .el-select-dropdown__item {
    background: none !important;
  }
  .el-icon-arrow-up {
    line-height: 0.1rem;
    color: #18b0d1;
  }
  .el-icon-arrow-up:before {
    color: #18b0d1;
  }
  &.el-select-dropdown {
    font-size: 0.12rem;
    width: 1.65rem;
    background-color: rgba(2, 5, 27, 1);
    border: 0.01rem solid rgba(5, 211, 255, 0.2);
    .el-select-group__title {
      color: rgba(5, 211, 255, 1);
      border-bottom: 0.01rem solid rgba(5, 200, 244, 0.1);
      padding-left: 0.1rem;
    }
    .el-select-dropdown__item {
      color: #fff !important;
      font-size: 0.14rem;
      padding: 0 0.15rem;
      &:hover {
        color: #18b0d1 !important;
      }
    }
    .el-select-group__wrap:not(:last-of-type)::after {
      background: rgba(5, 211, 255, 0.2);
      display: none;
    }
  }
  &.el-popper[x-placement^='bottom'] .popper__arrow {
    border-bottom-color: rgba(5, 211, 255, 0.2);
    &::after {
      border-bottom-color: #11254e;
    }
  }
}
</style>
