<template>
  <div class="countContainer">
    <ul class="countBoxWrap">
      <li class="countItem">
        <section class="liWrap">
          <span class="spanBlock totalTitle">{{ txt.oneTxt }}</span>
          <span class="spanBlock number">{{ countObject[fileds[0]] }}</span>
          <span class="spanBlock other">
            <span class="subTitle">{{ $t('screen.count.MoM') }}</span>
            <span
              class="otherNumber"
              :class="countObject[diffs[0]] >= 0 ? 'fontRed' : 'fontGreen'"
              >&nbsp;&nbsp;{{ calcForString(countObject[diffs[0]]) }}</span
            >
          </span>
        </section>
      </li>
      <li class="countItem">
        <section class="liWrap">
          <span class="spanBlock totalTitle">{{ txt.twoTxt }}</span>
          <span class="spanBlock number">{{ countObject[fileds[1]] }}</span>
          <span class="spanBlock other">
            <span class="subTitle">{{ $t('screen.count.MoM') }}</span>
            <span
              class="otherNumber"
              :class="countObject[diffs[1]] >= 0 ? 'fontRed' : 'fontGreen'"
              >&nbsp;&nbsp;{{ calcForString(countObject[diffs[1]]) }}</span
            >
          </span>
        </section>
      </li>
      <li class="countItem">
        <section class="liWrap">
          <span class="spanBlock totalTitle">{{ txt.threeTxt }}</span>
          <span class="spanBlock number">{{ countObject[fileds[2]] }}</span>
          <span class="spanBlock other">
            <span class="subTitle">{{ $t('screen.count.MoM') }}</span>
            <span
              class="otherNumber"
              :class="countObject[diffs[2]] >= 0 ? 'fontRed' : 'fontGreen'"
              >&nbsp;&nbsp;{{ calcForString(countObject[diffs[2]]) }}</span
            >
          </span>
        </section>
      </li>
      <li class="countItem">
        <section class="liWrap">
          <span class="spanBlock totalTitle">{{ txt.fourTxt }}</span>
          <span class="spanBlock number">{{
            calcForString(countObject[fileds[3]], '%')
          }}</span>
          <span class="spanBlock other">
            <span class="subTitle">{{ $t('screen.count.MoM') }}</span>
            <span
              class="otherNumber"
              :class="countObject[diffs[3]] >= 0 ? 'fontRed' : 'fontGreen'"
              >&nbsp;&nbsp;{{ calcForString(countObject[diffs[3]], '%') }}</span
            >
          </span>
        </section>
      </li>
    </ul>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { globalVueI18n } from '@triascloud/i18n';
const tokenFields = [
  'totalProjectCount',
  'totalBuildingCount',
  'deviceBindBuildingCount',
  'safeIndex',
];
const tokenDiffs = [
  'totalProjectCountDifference',
  'totalBuildingCountDifference',
  'deviceBindBuildingCountDifference',
  'safeIndexDifference',
];
const tokenTxt = {
  oneTxt: globalVueI18n.translate('screen.count.projects'),
  twoTxt: globalVueI18n.translate('screen.count.buildings'),
  threeTxt: globalVueI18n.translate('screen.count.terminalsInstalled'),
  fourTxt: globalVueI18n.translate('screen.count.monthlySafeRate'),
};
const appIdFields = [
  'totalProjectCount',
  'buildLoadingProjectCount',
  'buildLoadingBuildingCount',
  'safeIndex',
];
const appIdDiffs = [
  'totalProjectCountDifference',
  'buildLoadingProjectCountDifference',
  'buildLoadingBuildingCountDifference',
  'safeIndexDifference',
];
const appIdTxt = {
  oneTxt: globalVueI18n.translate('screen.count.projects'),
  twoTxt: globalVueI18n.translate('screen.count.startedProjects'),
  threeTxt: globalVueI18n.translate('screen.count.startedBuilding'),
  fourTxt: globalVueI18n.translate('screen.count.monthlySafeRate'),
};
@Component()
export default class TotalCount extends Vue {
  @Prop({ type: Boolean, default: true }) isToken;
  @Prop({
    type: Object,
    default: {
      buildLoadingCountDifference: 0,
      safeIndexDifference: 0,
      totalBuildingCountDifference: 0,
      waitEnterCountDifference: 0,
      safeIndex: 0,
      totalBuildingCount: 0,
      underConstructionBuildingCount: 0,
      waitEnterBuildingCount: 0,
    },
  })
  countObject;

  /**
   * @name 计算数值添加正负值
   * @param { Number } number 可计算的数值
   * @param { String } unit 单位
   */
  calcForString(number, unit) {
    if (
      number === undefined ||
      (!number && Object.prototype.toString.call(number) !== '[object Number]')
    ) {
      return '--';
    }
    let result = `${number}`;
    if (unit) {
      result = `${result}${unit}`;
    } else {
      if (number >= 0) {
        result = `+${number}`;
      }
    }
    return result;
  }
  get fileds() {
    return this.isToken ? tokenFields : appIdFields;
  }
  get diffs() {
    return this.isToken ? tokenDiffs : appIdDiffs;
  }
  get txt() {
    return this.isToken ? tokenTxt : appIdTxt;
  }
}
</script>
<style lang="less" scoped>
.countContainer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  .countBoxWrap {
    // width: 1.82rem;
    height: 1.38rem;
  }
  .countItem {
    background-color: rgba(2, 5, 27, 0.3);
    margin-right: 0.1rem;
    display: inline-block;
    width: 1.82rem;
    height: 100%;
    box-shadow: 0 0 0.1rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    position: relative;
    &:last-of-type {
      margin-right: 0;
    }
    .liWrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
    .spanBlock {
      display: block;
      text-align: center;
    }
    .totalTitle {
      color: rgba(5, 211, 255, 1);
      font-size: 0.16rem;
    }
    .number {
      font-size: 0.24rem;
      line-height: 0.48rem;
      font-weight: bolder;
      color: rgba(5, 211, 255, 1);
    }
    .other {
      font-size: 0.14rem;
      color: rgba(5, 211, 255, 1);
      .fontRed {
        color: rgba(255, 78, 78, 1);
      }
      .fontGreen {
        color: rgba(38, 226, 19, 1);
      }
    }
  }
}
</style>
