import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import Search from './search.vue';

export default function createSearchAreaBox(modalProps) {
  return new Promise(resolve => {
    const handleClose = val => {
      vm.handleClose(val);
      resolve(true);
    };

    const handleChangeSite = options => {
      vm.handleClose();
      // store.dispatch('screen/setAddMapUrlBoolean', options);
      resolve(options);
    };

    const elm = document.createElement('div');
    document.body.appendChild(elm);

    const vm = new Vue({
      el: elm,
      store,
      router,
      data() {
        return {
          visible: true,
          modalProps,
        };
      },
      methods: {
        handleClose(val = false) {
          this.visible = val;
          if (!val) {
            this.$destroy();
            this.$el.parentElement.removeChild(this.$el);
          }
        },
      },
      render() {
        return (
          <Search
            visible={this.visible}
            {...{ props: this.modalProps }}
            {...{
              on: {
                'update:visible': value => (this.visible = value),
                'close': handleClose,
                'changeSite': handleChangeSite,
              },
            }}
          />
        );
      },
    });
  });
}
