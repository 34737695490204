// import { promiseLock } from '@triascloud/utils';
import request from '../request';

const serviceName = '/iot-electric-box';

/**
 * @name 获取地址位置
 * @param { Object } options
 */
export function getAddressInfoByAmap(options) {
  return request(`${serviceName}/api/map/getAddressInfo`, {
    method: 'GET',
    body: options,
    auth: false,
  });
}
